<template>
  <vue-final-modal class="modal-cashback-bonus-details">
    <div class="vfm__head">
      <div class="vfm__control vfm__control-left" />

      <div class="vfm__title">
        {{ getContent(globalComponentsContent, defaultLocaleGlobalComponentsContent, 'bonuses.bonusDetailsTitle') }}
      </div>

      <div class="vfm__control vfm__control-right">
        <atomic-icon id="close" class="vfm__close" @click="closeModal('cashback-bonus-details')" />
      </div>
    </div>

    <div class="vfm__body">
      <card-cashback-bonus :bonusInfo="props.bonusInfo" hideButton />
      <div
        class="modal-cashback-bonus-details__subtitle"
        v-html="DOMPurify.sanitize(marked.parseInline(modalSubtitle) as string, { FORBID_TAGS: ['style'] })"
      />

      <div
        v-router-links
        class="modal-cashback-bonus-details__description"
        @click="clickDescriptionElement"
        v-html="DOMPurify.sanitize(marked.parseInline(modalDescription) as string, { FORBID_TAGS: ['style'] })"
      />

      <button-base
        class="modal-cashback-bonus-details__button"
        type="secondary-2"
        size="md"
        @click="closeModal('cashback-bonus-details')"
      >
        {{ getContent(props.currentLocaleData, props.defaultLocaleData, 'buttonLabel') }}
      </button-base>
    </div>
  </vue-final-modal>
</template>

<script setup lang="ts">
  import { marked } from 'marked';
  import DOMPurify from 'isomorphic-dompurify';
  import { VueFinalModal } from 'vue-final-modal';
  import type { CIFreeSpinDetailsModal, IPlayerCashback } from '~/types';

  const props = defineProps<{
    currentLocaleData: Maybe<CIFreeSpinDetailsModal>;
    defaultLocaleData: Maybe<CIFreeSpinDetailsModal>;
    bonusInfo: IPlayerCashback;
  }>();

  const { getContent, replaceContent } = useProjectMethods();
  const { closeModal } = useModalStore();
  const { globalComponentsContent, defaultLocaleGlobalComponentsContent } = useGlobalStore();

  const modalSubtitle = computed(() => {
    const subtitleContent = getContent(props.currentLocaleData, props.defaultLocaleData, 'subtitle') || '';

    return replaceContent(subtitleContent, '*');
  });

  const modalDescription = computed(
    () => getContent(props.currentLocaleData, props.defaultLocaleData, 'description') || ''
  );

  const clickDescriptionElement = (event: any): void => {
    const targetElement = event.target as HTMLElement;
    if (targetElement.tagName === 'A') closeModal('cashback-bonus-details');
  };
</script>

<style src="~/assets/styles/components/modal/cashback-bonus-details.scss" lang="scss" />
